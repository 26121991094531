import styled from "styled-components";

export const FooterWrapper = styled.div`
  --accentColor: ${(props) => props.color || "var(--green)"};
  background-color: var(--green);
  color: var(--black);
  display: flex;
  height: 299px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  & > div {
    display: flex;
    width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
    align-items: bottom;
    justify-content: space-between;

    & > div:last-of-type {
      justify-content: flex-end;
      flex-direction: column;
      @media (max-width: 1439px) {
        margin-top: 24px;
        justify-content: center;
        & > a:first-of-type {
          max-width: 100%;
          & > div > div {
            &.gatsby-image-wrapper {
              max-width: 100%;
            }
          }
        }
      }
    }

    & > div:first-of-type {
      flex: 2;
      margin-top: auto;
      max-width: 820px;
      align-items: bottom;
      & + div {
        flex: 1;
        margin-top: auto;
        max-width: 400px;
        margin-left: 20px;
        align-items: center;
        /* border-bottom: 1px solid var(--accentColor); */
        & svg {
          position: relative;
          right: -100px;
          right: -52px;
        }
        & ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          &.copyright_footer {
            border-bottom: 1px solid var(--accentColor);
          }
          & li {
            font-size: 12px;
            /* text-transform: lowercase; */
            letter-spacing: 0.36px;
            display: inline-flex;
            align-items: flex-end;
            display: flex;
            & span {
              flex: 2;
              width: 100%;
              position: relative;
              white-space: nowrap;
              bottom: 9px;
            }
          }
        }
      }
    }

    & > div:nth-child(2) {
      @media (min-width: 768px) {
        display: none;
      }
    }

    & > div {
      display: flex;
      justify-content: space-between;
      & > div:first-of-type {
        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      & > ul {
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & > div {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        & > ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          @media (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-height: 159px;
            margin-bottom: 16px;
          }

          & li:nth-child(1),
          li:nth-child(2),
          li:nth-child(3) {
            margin-right: 12px;
          }

          & li {
            &.mobileIcons {
              @media (min-width: 768px) {
                display: none;
              }
            }
            height: 35px;
            margin: 0 0 6px;
            font-size: 18px;
            font-weight: normal;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--accentColor);
            @media (max-width: 768px) {
              min-width: calc(50% - 12px);
            }
            /* text-transform: lowercase; */
            & a {
              display: inline-flex;
              align-items: center;
              transition: 0.25s;
              color: var(--black);
              padding-bottom: 6px;
              &:hover {
                color: var(--accentColor);
              }
              & img {
                margin-right: 8px;
              }
            }
            &.logo {
              > a {
                padding-bottom: 0;
              }
              padding-bottom: 20px;
              height: fit-content;
            }
            &.big {
              height: 50px;
              margin-bottom: 0;
            }
            &.addspace {
              margin-top: 53px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    height: initial;
    padding: 25px 0;
    & > div {
      flex-direction: column;
      width: initial;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      align-items: center;
      & > li {
        font-size: 16px;
      }
      & > div > div {
        & > ul li {
          width: 161px;
          /* height: 35px; */
          height: fit-content;
          margin-bottom: 6px;
          &.big {
            height: 45px;
          }
        }
      }
      & > div:first-of-type {
        max-width: unset;
        gap: 20px;
        & div {
          width: initial;
          flex: 1;
          &:first-of-type {
            margin-right: 12px;
          }
        }
        & + div {
          margin-left: 0;
          max-width: 100%;
          & ul li {
            align-items: center;
            height: 100px;
            width: 334px;
            @media (max-width: 768px) {
              width: 100%;
              svg {
                right: -100px;
              }
            }
            & span {
              white-space: initial;
              min-width: 200px;
              bottom: 0;
            }
            & a {
              position: relative;
              right: -4px;
              width: 50%;
              & svg {
                width: fit-content;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -100px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    & > div {
      & > div > div {
        & > ul li {
          max-height: 34px;
        }
      }
    }
    padding: 25px 20px;
  }

  .CookieConsent {
    height: 37px;
    font-size: 14px;
    display: block !important;
  }
  .CookieConsent div:first-child {
    margin: 10px 15px !important;
    display: inline-block;
    width: 77%;
  }
  .CookieConsent div:last-child {
    display: inline-block;
    width: 20%;
    text-align: right;
  }

  .CookieConsent .txt_bold {
    font-weight: 700;
  }
  .CookieConsent .cookie_txt {
    margin: -4px;
  }
  .CookieConsent .cookie_txt .cookieunderline {
    text-decoration: underline;
  }
  .cookieunderline a {
    color: white;
  }

  .CookieConsent #rcc-decline-button {
    background: #2fed1c !important;
    border: 1px solid !important;
    margin: 5px !important;
    color: white !important;
  }
  .CookieConsent #rcc-confirm-button {
    background: #2fed1c !important;
    border: 1px solid !important;
    color: rgb(229, 229, 229) !important;
    margin: 5px !important;
    color: white !important;
  }
  @media only screen and (max-width: 991px) {
    .CookieConsent {
      height: auto;
      text-align: center;
    }
    .CookieConsent div:last-child {
      width: 100%;
      text-align: center;
    }
  }
`;

export const NewsletterWrapper = styled.div`
  position: relative;
  height: 149px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const NewsletterGradient = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(0, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66));
`;

export const NewsletterContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--green);
`;
