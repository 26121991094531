import React from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import GlobalContext from "./../GlobalContext";
import Helmet from "react-helmet";
import Header from "./../Header";
import MainWrapper from "./../MainWrapper";
import Footer from "./../Footer";
import Menu from "./../Menu";
import { MenuWrapper, Wrapper } from "./elements";
import { Location } from "@reach/router";

const Layout = ({
  children,
  footerColor,
  backgroundColor,
  headerColor,
  aboveFooter,
  fullWidth,
  headerBlack,
  skipHeader,
  isFixed,
  title,
  seoTitle,
  SEODescription,
  SEOImageURL,
  SEOIsArticle,
}) => {
  const [menuShown, setMenuShown] = React.useState(false);

  const globalQueryBase = useStaticQuery(graphql`
    {
      craftGlobalSetInterface(handle: { eq: "siteQAFSettings" }) {
        ... on Craft_siteQAFSettings_GlobalSet {
          id
          pageQAFTitleSeo
          pageQAFDesciptionSeo
          twitterLink
          remoteId
          linkedinLink
          facebookLink
          instagramLink
          ticketButtonText
          ticketButtonLink
          newsletterTitle
          newsletterDescription
          newsletterImage {
            ... on Craft_qafAssets_Asset {
              id
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          newsletterLink
          menuContent {
            ... on Craft_menuContent_menuSection_BlockType {
              menuSectionSubitems {
                link
                parentLink
                name
              }
              menuItem {
                link
                name
              }
            }
          }
          footerContent {
            ... on Craft_footerContent_footerEntry_BlockType {
              id
              footerItemName
              footerItemLink
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          useStaging
        }
      }
      file(relativePath: { eq: "logo-alserkal-black.png" }) {
        publicURL
      }
    }
  `);

  const globalQuery = globalQueryBase.craftGlobalSetInterface;
  globalQuery.siteURL = globalQueryBase.site.siteMetadata.siteUrl;
  globalQuery.useStaging =
    globalQueryBase.site.siteMetadata.useStaging === "true";
  const fallbackLogo =
    "https://alserkal.online/" + globalQueryBase.file.publicURL;
  // console.log("rendering!", menuShown);
  const fallbackTitle =
    globalQuery.pageQAFTitleSeo ||
    "Alserkal.online: a digital platform by the creative community";
  const fallbackDescription =
    globalQuery.pageQAFDesciptionSeo ||
    "Contemporary arts and culture, discourse, digital art commissions and inter-disciplinary content supporting diverse pertinent contemporary narratives";
  const ticketButtonText = globalQuery.ticketButtonText || "TICKETS";
  const ticketButtonLink =
    globalQuery.ticketButtonLink || "https://alserkal.online";
  const newsLetterImage = globalQuery.newsletterImage?.length
    ? globalQuery.newsletterImage[0]
    : null;
  const newsletterTitle = globalQuery.newsletterTitle || "Join our newsletter";
  const newsletterDescription =
    globalQuery.newsletterDescription ||
    "Be in the know on everything Alserkal";
  const newsletterLink =
    globalQuery.newsletterLink ||
    "https://alserkalavenue.us8.list-manage.com/subscribe?u=89ea5b35584c53ada3b153c0f&id=db108ae6b9";

  return (
    <Location>
      {({ location }) => {
        const myPath = encodeURI(globalQuery.siteURL + location.pathname);
        return (
          <GlobalContext.Provider value={{ ...globalQuery, useStore: false }}>
            {globalQuery.useStaging ? null : (
              <Helmet>
                <title>{title || fallbackTitle}</title>
                <meta
                  name="description"
                  content={SEODescription || fallbackDescription}
                />
                <meta
                  itemprop="name"
                  content={title ? `Alserkal Avenue: ${title}` : fallbackTitle}
                />
                <meta
                  itemprop="description"
                  content={SEODescription || fallbackDescription}
                />
                <meta itemprop="image" content={SEOImageURL || fallbackLogo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@AlserkalAvenue" />
                <meta name="twitter:creator" content="@AlserkalAvenue" />
                <meta
                  name="twitter:title"
                  content={title ? `Alserkal Avenue: ${title}` : fallbackTitle}
                />
                <meta
                  name="twitter:description"
                  content={SEODescription || fallbackDescription}
                />
                <meta
                  name="twitter:image"
                  content={SEOImageURL || fallbackLogo}
                />
                <meta
                  property="og:title"
                  content={title ? `Alserkal Avenue: ${title}` : fallbackTitle}
                />
                <meta
                  property="og:image"
                  content={SEOImageURL || fallbackLogo}
                />
                <meta property="og:image:width" content={1200} />
                <meta property="og:image:height" content={600} />
                <meta
                  property="og:type"
                  content={SEOIsArticle ? "article" : "website"}
                />
                <meta
                  property="og:description"
                  content={SEODescription || fallbackDescription}
                />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:url" content={myPath} />
                <link rel="canonical" href={myPath} />
                <meta
                  name="facebook-domain-verification"
                  content="yftph99ln6t2q5v3om8t5jcd9id5dx"
                />
              </Helmet>
            )}
            <MenuWrapper>
              <Wrapper backgroundColor={backgroundColor}>
                <Header
                  skipHeader={skipHeader}
                  darkMode={true}
                  headerColor={headerColor}
                  headerBlack={headerBlack}
                  openUser={(e) => {
                    console.log("open user");
                  }}
                  openSearch={(e) => {
                    navigate(`/search`);
                  }}
                  openStore={(e) => {
                    // console.log("open store");
                    navigate(`/tote`);
                  }}
                  openMenu={(e) => {
                    // console.log("menuShown:", menuShown);
                    setMenuShown(true);
                  }}
                  hideSearch={false}
                  ticketButtonText={ticketButtonText}
                  ticketButtonLink={ticketButtonLink}
                />
                <MainWrapper
                  backgroundColor={backgroundColor}
                  fullWidth
                  isFixed={isFixed}
                >
                  {children}
                </MainWrapper>
                {aboveFooter}
                <Footer
                  color={footerColor || "var(--green)"}
                  // backgroundColorFooter={footerColor || "var(--green)"}
                  reportLink={(e) => {
                    // console.log("Footer selected: ", e);
                    navigate(`/${e}`);
                  }}
                  newsletterTitle={newsletterTitle}
                  newsletterDescription={newsletterDescription}
                  newsLetterImage={newsLetterImage}
                  newsletterLink={newsletterLink}
                />
              </Wrapper>
              <nav className={menuShown ? "shown" : "hidden"}>
                <Menu
                  onCloseMenu={() => {
                    setMenuShown(false);
                  }}
                  onItemSelected={(e) => {
                    console.log("Menu selected: ", e);
                    navigate(`/${e}`);
                  }}
                />
              </nav>
            </MenuWrapper>
          </GlobalContext.Provider>
        );
      }}
    </Location>
  );
};

export default Layout;

Layout.defaultProps = {
  skipHeader: false,
};
