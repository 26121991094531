import React from "react";
import PropTypes from "prop-types";
import GlobalContext from "./../GlobalContext";
import { Link } from "@reach/router";
import {
  FooterWrapper,
  NewsletterContent,
  NewsletterGradient,
  NewsletterWrapper,
} from "./elements";
import Logo from "./../Logo";
import MiscSmallSocial from "./../icons/MiscSocialSmall";
import MiscSocialDarkSmall from "./../icons/MiscSocialDarkSmall";
import Img from "gatsby-image";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

// import SocialButtons from "../../assets/icons/misc-social-small.svg";
import UserIcon from "./../../assets/icons/icons-account.svg";
import { H3Strong, H5 } from "../Typography";

/**
- This expects two arrays of paired names and IDs. How often does this change?
- IDs will probably be turned into a link?
- If an ID has `emphasize` set to true, we could make it green (like in SlidingMenu)
**/

const turnOffCommunityLogin = true;

const Footer = ({
  leftList,
  rightList,
  copyright,
  reportLink,
  color,
  newsletterTitle,
  newsletterDescription,
  newsLetterImage,
  newsletterLink,
}) => {
  const { footerContent, footerCopyright } = React.useContext(GlobalContext);

  const footerContentRendered = [...footerContent];
  footerContentRendered.splice(footerContent.length / 2, 0, "icons");

  // const theLeftList = footerContent
  //   ? footerContent.slice(0, Math.ceil(footerContent.length / 2)).map((x) => {
  //       return { id: x.footerItemLink, name: x.footerItemName };
  //     })
  //   : leftList;
  // const theRightList = footerContent
  //   ? footerContent
  //       .slice(Math.ceil(footerContent.length / 2), footerContent.length)
  //       .map((x) => {
  //         return { id: x.footerItemLink, name: x.footerItemName };
  //       })
  //   : rightList;

  const thisYear = new Date();

  return (
    // <FooterWrapper color={color || "var(--green)"}>
    <FooterWrapper id="footer" color="var(--black)">
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
	      cookieName="Alserkal"       
	      style={{background:"#2fed1c"}}
	      buttonStyle={{color:"red"}}
        enableDeclineButton
        declineButtonText="Decline"
      >
        <span class="cookie_txt">         
         <span class="txt_bold">Your privacy. </span> We use cookies to improve your experience on our site. To find out more, read our <span class='cookieunderline'> <a href="https://alserkal.online/privacy/">privacy policy</a> </span> 
        </span>
      </CookieConsent> */}

      <div>
        <div>
          <div>
            <ul>
              <li className="logo">
                <a to="https://alserkal.online" target="_blank">
                  <Logo />
                </a>
              </li>
              <li className="big">
                <MiscSocialDarkSmall
                  extraClass="mobileIcons"
                  useInstagram
                  color="var(--green)"
                />
              </li>
            </ul>
          </div>

          <div>
            {footerContentRendered && footerContentRendered?.length ? (
              <ul>
                {footerContentRendered.map((item, index) => (
                  <li
                    key={index}
                    className={
                      index === footerContentRendered.length - 1
                        ? "big"
                        : "" + item === "icons"
                        ? " mobileIcons"
                        : ""
                    }
                  >
                    {item === "icons" ? (
                      <MiscSocialDarkSmall
                        extraClass="mobileIcons"
                        useInstagram
                        color="var(--green)"
                      />
                    ) : item.id == "newsletter" ? (
                      <a href="http://eepurl.com/hzEv61">{item.name}</a>
                    ) : (
                      <a
                        href={
                          item.footerItemLink === "alserkal.online"
                            ? `https://alserkal.online`
                            : `/${item.footerItemLink}`
                        }
                        target={
                          item.footerItemLink === "alserkal.online"
                            ? "_blank"
                            : undefined
                        }
                        onClick={(e) => {
                          if (item.footerItemLink !== "alserkal.online") {
                            e.preventDefault();
                            reportLink(item.footerItemLink);
                          }
                          if (
                            item.footerItemLink === "programme" &&
                            typeof window !== "undefined"
                          ) {
                            localStorage.removeItem("theme");
                          }
                        }}
                      >
                        {item.footerItemName}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>

        <div>
          <ul className="copyright_footer">
            <li>
              <span>
                © {thisYear.getFullYear()}{" "}
                {footerCopyright ||
                  copyright ||
                  "All Rights Reserved. Alserkal Avenue LLC."}
              </span>
              <Link to={"/"}>
                <Logo noText />
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <a href={newsletterLink} target="_blank">
            <NewsletterWrapper>
              <Img
                fixed={
                  newsLetterImage?.cardImage?.childImageSharp?.fixed || null
                }
                alt="Newsletter Background"
                style={{
                  width: "400px",
                  height: "149px",
                }}
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
              <NewsletterGradient />
              <NewsletterContent>
                <H3Strong>{newsletterTitle}</H3Strong>
                <H5>{newsletterDescription}</H5>
              </NewsletterContent>
            </NewsletterWrapper>
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;

Footer.propTypes = {
  leftList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      emphasize: PropTypes.bool,
    })
  ),
  rightLight: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      emphasize: PropTypes.bool,
    })
  ),
  copyright: PropTypes.string,
  reportLink: PropTypes.func,
  newsletterTitle: PropTypes.string,
  newsletterDescription: PropTypes.string,
  newsLetterImage: PropTypes.any,
};
