import React from "react";
import logo from "../../assets/gifs/logo.gif";
import { Logo } from "./elements";
// import PropTypes from "prop-types";

const LogoGif = () => <Logo src={logo} alt="Alserkal Logo" />;

export default LogoGif;

// Logo.propTypes = {
//   color: PropTypes.string,
//   noText: PropTypes.bool,
// };
