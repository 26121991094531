import React from "react";
import PropTypes from "prop-types";
import { Background, ButtonContainer, Text } from "./elements";
import background from "./images/background.png";

const TicketButton = ({ ticketButtonText }) => {
  return (
    <ButtonContainer>
      <Background src={background} alt="Ticket Background" />
      <Text>{ticketButtonText}</Text>
    </ButtonContainer>
  );
};

export default TicketButton;

TicketButton.propsType = {
  ticketButtonText: PropTypes.string,
};
